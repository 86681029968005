import { getAppData } from 'bit-uibl-data-driver/dist/Backend.service';
import React, { useEffect,useState } from 'react';
import { async } from 'regenerator-runtime';

import * as divstyles from '../styles/head.module.css';
import JSONLoader from './JSONLoader';
//import fetchHelper from '../helper/fetch-helper';;
const pc = require( 'bit-uibl-data-driver' );

//.then(res=>console.log(JSON.parse(res.Payload).body))
//'116851091899315a1873275163165185'
function Details4Ids() {

  const [ input, setInput ] = useState( ' ' );
  const [ uid, setUid ] = useState( ' ' );
  const [ isSubmitted, setIsSubmitted ] = useState( false );
  const [ data, setData ] = useState( [] );
  //const [uidArr, setUidArr] = useState([]);
  //const [isClicked,setIsClicked]=useState(false);

  // function getmultipledata(uids){
  //   pc.BackendService.getAppdata4Ids(uids)
  //   .then(response=>{
  //     var multipledata=response;
  //     console.log(multipledata);
  //   })
  // }

  function getdata( uid ) {

    pc.BackendService.getAppData( uid )
      .then( response => {
        let clientdata = JSON.parse( response.Payload ).body;
        delete clientdata['browserInfo'];
        let info = [ ...data, clientdata ];
        setData( info );
      } );
  }

  function handleSubmit() {
    setUid( input );
    let tmp = [];
    tmp = input.split( ',' );
    console.log( tmp );
    tmp = trimArray( tmp );
    console.log( 'Submitted clicked' );

    console.log( tmp );
    for ( const elem of tmp ) {
      getdata( elem );
    }
    console.log( 'Submitted True' );
    setIsSubmitted( true );
  }

  // function handleClick(event){
  //   var cur=event.target.textContent;
  //   console.log(cur+" clicked");
  //   console.log("getdata in handleclick")
  //   setIsClicked(true);
  //   console.log(data);
  // }

  const handleChange = ( event ) => {
    setInput( event.target.value );
  };

  const trimArray = ( arr ) => {
    for ( var i = 0; i < arr.length; i++ ) {
      arr[i] = arr[i].replace( /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '' );
    }
    return arr.slice( 0, 10 );
  };


  return (
    <>
      <div>
        <textarea
          name="input"
          className={divstyles.textbox}
          placeholder="Enter id"
          value={input}
          onChange={handleChange}
        ></textarea>

        <h1>UIDs:{uid}</h1>

        <br />

        <button type="submit" onClick={handleSubmit}>Submit</button>

        {/* <button type="submit" onclick={getmultipledataforuids}>Get Multiple data</button> */}

        {/* {isSubmitted && uidArr.map((e, i) => <li key={i} onClick={handleClick}>{e}</li>)} */}

        {/* {isClicked && <JSONLoader data={data}/>} */}
      </div>

      <div className={divstyles.dataGrid}>
        {isSubmitted && <JSONLoader data={data} />}
      </div>
    </>
  );

}
export default Details4Ids;
